import { t } from "@lingui/macro";
import { cn } from "@rocket-resume/utils";
import { useMemo } from "react";
import { Link, matchRoutes, Outlet, useLocation } from "react-router-dom";
import logoWhite from '@/client/assets/LogoLight.svg'
import { LocaleSwitch } from "@/client/components/locale-switch";
import { Logo } from "@/client/components/logo";
import { ThemeSwitch } from "@/client/components/theme-switch";
import { useAuthProviders } from "@/client/services/auth/providers";

import { SocialAuth } from "./_components/social-auth";

const authRoutes = [{ path: "/auth/login" }, { path: "/auth/register" }];

export const AuthLayout = () => {
  const location = useLocation();
  const { providers } = useAuthProviders();
  const isAuthRoute = useMemo(() => matchRoutes(authRoutes, location) !== null, [location]);

  if (!providers) return null;

  // Condition (providers.length === 1) hides the divider if providers[] includes only "email"
  const hideDivider = !providers.includes("email") || providers.length === 1;

  return (
    // eslint-disable-next-line tailwindcss/enforces-shorthand -- size-screen not implemented yet
    <div className="flex items-center justify-center min-h-screen w-[98vw] bg-[#000]">
      <div className="flex h-[78%] w-[86%] bg-[url('@/client/assets/images/sign_up_bg.png')] bg-cover">
        <div className="relative flex h-[40rem] w-full flex-col gap-y-8 pr-4 xs:pr-8 sm:pr-0 pl-4 xs:pl-12 sm:mx-auto basis-[550px] lg:pl-20 mt-4 xs:mt-24">
          <div className="flex items-center justify-between">
            <Link to="/" className="size-30 flex items-center justify-center">
              {/* <Logo className="" size={100} /> */}
              <img
                src={logoWhite}
                width={100}
                height={100}
                alt="Rocket Resume"
                className="rounded-sm -ml-3"
              />
            </Link>
            <LocaleSwitch />
            {/* <ThemeSwitch /> */}
          </div>

          <Outlet />

          {isAuthRoute && (
            <>
              <div className={cn("flex items-center gap-x-4", hideDivider && "hidden")}>
                <hr className="flex-1" />
                <span className="text-xs font-medium">
                  {t({
                    message: "or continue with",
                    context:
                      "The user can either login with email/password, or continue with GitHub or Google.",
                  })}
                </span>
                <hr className="flex-1" />
              </div>

              <SocialAuth />
            </>
          )}
        </div>

        <div className="relative hidden lg:block md:flex-1 mt-16 bg-[url('@/client/assets/images/signup_rocket.png')] bg-contain bg-no-repeat bg-right"></div>
      </div>
    </div>
  );
};
